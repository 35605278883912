<template>
  <div class="potential-customers-detail">
    <HeaderNavigation class="mb-12" />
    <Form
      ref="form"
      :rows="
        activeName === 'potentialCustomersShidanliDetail' ? rows : personRows
      "
      v-model="detail"
    />
    <Navigation class="mb-12" :navigation-item="navigationItem" />
    <RowLink :links="links" />
  </div>
</template>
<script>
import HeaderNavigation from "@/components/header/headerWithNavigation";
import Form from "@/components/common/form/index.vue";
import Navigation from "@/components/navigation/index.vue";
import RowLink from "@/components/select/rowLink.vue";
import { potentialCustomersApi } from "@/utils/api.js";
import dayjs from "dayjs";
export default {
  components: {
    HeaderNavigation,
    Form,
    Navigation,
    RowLink,
  },
  data() {
    return {
      loading: false,
      id: "",
      activeName: "",
      detail: {},
      rows: [
        {
          key: "potentialTypeName",
          label: "潜在客户类型",
          gap: true,
        },
        { key: "contactPerson", label: "联系人" },
        { key: "mobile", label: "手机" },
        { key: "birthday", label: "生日", gap: true },
        { key: "businessMasterName", label: "业务主任" },
        { key: "dealerName", label: "所属经销商" },
        { key: "region", label: "所属市县" },
        { key: "town", label: "所属乡镇" },
        { key: "village", label: "所属村", gap: true },
        { key: "villageCoverLabel", label: "村级覆盖" },
        { key: "plantCover", label: "作物覆盖", gap: true },
        { key: "runCompBrand", label: "经营竞品品牌" },
        { key: "otherCompBrand", label: "其他竞品品牌", gap: true },
        { key: "totalCapacity", label: "去年复合肥总容量" },
        { key: "bussinessManName", label: "属地业务员", gap: true },
      ],
      personRows: [
        {
          key: "potentialTypeName",
          label: "潜在客户类型",
          gap: true,
        },
        { key: "contactPerson", label: "联系人" },
        { key: "mobile", label: "手机" },
        { key: "birthday", label: "生日", gap: true },
        { key: "dealerName", label: "所属经销商" },
        { key: "region", label: "所属市县" },
        { key: "town", label: "所属乡镇" },
        { key: "village", label: "所属村", gap: true },
        { key: "plantCover", label: "作物覆盖", gap: true },
        { key: "runCompBrand", label: "经营竞品品牌", gap: true },
        { key: "bussinessManName", label: "属地业务员", gap: true },
      ],
    };
  },
  computed: {
    navigationItem() {
      const point = this.detail.coordinate || "";
      const [lng, lat] = point.split(",");
      return { lng, lat, position: this.detail.location };
    },
    links() {
      if (this.activeName === "potentialCustomersShidanliDetail") {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=potentialCustomers&id=${this.id}&tab=shidanli`,
          },
        ];
      } else {
        return [
          {
            label: "近期拜访记录",
            path: `/visit/list?type=potentialCustomers&id=${this.id}&tab=ziyou`,
          },
        ];
      }
    },
  },
  activated() {
    this.activeName = this.$route.name;
    this.id = this.$route.params.id;
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      let response = "";
      if (this.activeName === "potentialCustomersShidanliDetail") {
        response = await potentialCustomersApi.detail({
          id: this.id,
        });
      } else {
        response = await potentialCustomersApi.distDetail({
          id: this.id,
        });
      }
      this.detail = {
        ...response.data,
        birthday: dayjs(new Date(response.data.birthday)).format("YYYY-MM-DD"),
        villageCoverLabel: response.data.villageCover ? "是" : "否",
      };
    },
    async delete() {},
  },
};
</script>
<style lang="scss" scoped>
.potential-customers-detail {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  padding-bottom: 56px;
}
</style>
